import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const invalidChars = ["-", "+", "e", "."];
const invalidchr = ["-", "+", "e"];
const ExistingRow = ({ value, updateFieldValue, index, setorderp }) => {
  const [openDiscountBox, setopenDiscountBox] = useState(false);
  const [placeholderValue, setPlaceholderValue] = useState("");
  const [subTotal, setsubTotal] = useState("");
  const handleOptionClick = (value, index) => {
    setopenDiscountBox(false);
    setPlaceholderValue(value);
    updateFieldValue(index, "discount_type", value);
  };

  // For scroll disable
  var inputTypeNumbers = document.querySelectorAll("input[type=number]");
  for (var a = 0; a < inputTypeNumbers.length; a++) {
    inputTypeNumbers[a].onwheel = function (event) {
      event.target.blur();
    };
  }
  useEffect(() => {
    // updateFieldValue(
    //   index,
    //   "amount",
    //   ((value.rate * value.quantity) / 100) *
    //     (Number(value.sgst) + Number(value.cgst)) +
    //     value.rate * value.quantity
    // );
    // setsubTotal(
    //   ((value.rate * value.quantity) / 100) *
    //     (Number(value.sgst) + Number(value.cgst)) +
    //     value.rate * value.quantity
    // );
    const InitalTotalAmouunt =
      ((value.rate * value.quantity) / 100) * (value.sgst + value.cgst) +
      value.rate * value.quantity;
    updateFieldValue(
      index,
      "amount",
      placeholderValue === "Percentage"
        ? InitalTotalAmouunt -
            (InitalTotalAmouunt * (value.discount || 0)) / 100
        : InitalTotalAmouunt -
            (InitalTotalAmouunt === 0 ? 0 : value.discount || 0)
    );
    setsubTotal(
      placeholderValue === "Percentage"
        ? InitalTotalAmouunt -
            (InitalTotalAmouunt * (value.discount || 0)) / 100
        : InitalTotalAmouunt -
            (InitalTotalAmouunt === 0 ? 0 : value.discount || 0)
    );
  }, [
    value.hsn_sac,
    value.rate,
    value.sgst,
    value.cgst,
    value.quantity,
    value.discount,
    placeholderValue,
  ]);
  return (
    <tr>
      <td
        className="text-center border_td"
        onClick={() => {
          console.log(
            (value.rate *
              value.quantity *
              (Number(value.cgst) + Number(value.sgst))) /
              100
          );
        }}
      >
        {index + 1}
      </td>
      <td className=" border_td">{value.material_name}</td>
      <td className="text-center border_td">
        <input
          className="potable_input w-100"
          type="number"
          onChange={(e) => {
            setorderp(false);
            updateFieldValue(index, "hsn_sac", e.target.value);
            // console.log("arrayApihi", arrayApi);
          }}
          required=""
          placeholder="eg 1452652178"
          onKeyDown={(event) => {
            const value = event.target.value;
            if (
              value.length >= 8 &&
              event.keyCode !== 8 &&
              event.keyCode !== 37 &&
              event.keyCode !== 39
            ) {
              event.preventDefault();
            }
            return invalidChars.includes(event.key) && event.preventDefault();
          }}
        />
      </td>
      <td className="text-center border_td">{value.uom}</td>
      <td className="text-center border_td">
        <input
          className="potable_input w-75 text-center"
          type="number"
          value={value.quantity || ""}
          placeholder="eg. 61.00"
          onChange={(e) => {
            setorderp(false);
            updateFieldValue(index, "quantity", e.target.value);
            // console.log("arrayApihi", arrayApi);
          }}
          required=""
        />
        {/* {value.quantity} */}
      </td>
      <td className="text-center border_td">
        <div className="d-flex align-items-center justify-content-center">
          <p className="₹ mb-0">₹</p>
          <input
            className="potable_input w-100"
            type="number"
            // value={value.rate || ""}
            placeholder="eg. 61.00"
            onChange={(e) => {
              setorderp(false);
              updateFieldValue(index, "rate", Number(e.target.value));
              // console.log("arrayApihi", arrayApi);
            }}
            required=""
            onKeyDown={(event) => {
              const value = event.target.value;
              if (
                value.length >= 10 &&
                event.keyCode !== 8 &&
                event.keyCode !== 37 &&
                event.keyCode !== 39
              ) {
                event.preventDefault();
              }
              return invalidchr.includes(event.key) && event.preventDefault();
            }}
          />
        </div>
      </td>
      <td className="text-center border_td">
        {" "}
        <div className="d-flex align-items-center justify-content-center">
          <input
            className="potable_input w-100"
            type="text"
            required=""
            // value={value.sgst || ""}
            onChange={(e) => {
              setorderp(false);
              updateFieldValue(index, "sgst", Number(e.target.value || NaN));
              // console.log("arrayApihi", arrayApi);
            }}
            placeholder="eg 10"
            onKeyDown={(event) => {
              const pattern = /^\d{0,4}(\.\d{0,4})?$/;
              const currentValue = event.target.value;

              if (
                event.keyCode === 8 ||
                event.keyCode === 37 ||
                event.keyCode === 39
              ) {
                return;
              }

              const key = event.key;
              const newValue = currentValue + key;
              if (!pattern.test(newValue)) {
                event.preventDefault();
              }
            }}
          />
          <p className="₹ mb-0">%</p>
        </div>
      </td>
      <td className="text-center border_td">
        {" "}
        <div className="d-flex align-items-center justify-content-center">
          <input
            className={`potable_input w-100 ${
              value.sgst !== value.cgst && "errorfile"
            }`}
            type="text"
            required=""
            // value={value.cgst || ""}
            onChange={(e) => {
              setorderp(false);
              updateFieldValue(index, "cgst", Number(e.target.value || NaN));
              // console.log("arrayApihi", arrayApi);
            }}
            placeholder="eg. 10"
            onKeyDown={(event) => {
              const pattern = /^\d{0,4}(\.\d{0,4})?$/;
              const currentValue = event.target.value;

              if (
                event.keyCode === 8 ||
                event.keyCode === 37 ||
                event.keyCode === 39
              ) {
                return;
              }

              const key = event.key;
              const newValue = currentValue + key;
              if (!pattern.test(newValue)) {
                event.preventDefault();
              }
            }}
          />
          <p className="₹ mb-0">%</p>
        </div>
      </td>
      <td className="text-center border_td">
        <div className="d-flex align-items-center justify-content-center">
          <span
            className={`${
              placeholderValue === "Amount"
                ? "input-euro left"
                : placeholderValue === "Percentage"
                ? "perctange right"
                : ""
            }`}
          >
            <input
              disabled={placeholderValue === ""}
              type="text"
              className="potable_input discountinput potable_input w-100 "
              // value={value.discount || ""}
              onChange={(e) => {
                setorderp(false);
                updateFieldValue(
                  index,
                  "discount",
                  Number(e.target.value) || 0
                );

                updateFieldValue(index, "discount_type", placeholderValue);
              }}
              placeholder="0"
              onKeyDown={(event) => {
                const value = event.target.value;
                if (placeholderValue === "Percentage") {
                  if (
                    event.key === "Backspace" ||
                    event.keyCode === 37 ||
                    event.keyCode === 39
                  ) {
                    return;
                  }
                  const inputValue = event.target.value + event.key;
                  const numericValue = parseFloat(inputValue);
                  if (
                    !/^\d*\.?\d*$/.test(inputValue) || // Allow only digits and a single decimal point
                    numericValue < 0 || // Prevent negative numbers
                    numericValue > 100 || // Prevent values greater than 100
                    (inputValue.includes(".") &&
                      inputValue.split(".")[1].length > 2) // Allow up to 2 decimal places
                  ) {
                    event.preventDefault();
                  }
                } else {
                  const pattern = /^\d{0,5}(\.\d{0,5})?$/;
                  const currentValue = event.target.value;
                  if (
                    event.keyCode === 8 ||
                    event.keyCode === 37 ||
                    event.keyCode === 39
                  ) {
                    return;
                  }

                  const key = event.key;
                  const newValue = currentValue + key;
                  if (!pattern.test(newValue)) {
                    event.preventDefault();
                  }
                }
              }}
            />
          </span>
          <div
            className="₹ discounttopdiv"
            onClick={() => setopenDiscountBox(!openDiscountBox)}
          >
            <KeyboardArrowDownIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  width: "18px",
                  height: "18px",
                  transform: openDiscountBox && "rotate(180deg)",
                },
              }}
            />
            {openDiscountBox && (
              <div className="MaterialDiscount">
                <p
                  onClick={() => {
                    setorderp(false);
                    handleOptionClick("Percentage", index);
                  }}
                  className="percetnagepara"
                >
                  Percentage
                </p>
                <p
                  onClick={() => {
                    setorderp(false);
                    handleOptionClick("Amount", index);
                  }}
                  className="amountpara"
                >
                  Amount
                </p>
              </div>
            )}
          </div>
        </div>
      </td>

      <td className="text-center border_td">
        {Number.isNaN(subTotal) ? 0 : Number(subTotal).toFixed(2)}
      </td>
    </tr>
  );
};

export default ExistingRow;
