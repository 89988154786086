import React, { useEffect, useState } from "react";
import logo from "../Assets/svg/cendrol_logo.svg";
import contact_us from "../Assets/svg/Calling.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const invalidChars = ["-", "+", "e", "."];
const invalidchr = ["-", "+", "e"];
export default function MobileTable({
  value,
  updateFieldValue,
  index,
  setorderp,
}) {
  const [subTotal, setsubTotal] = useState("");
  const [Mobile, setMobile] = useState(false);
  const [openDiscountBox, setopenDiscountBox] = useState(false);
  const [placeholderValue, setPlaceholderValue] = useState("Percentage");
  const handleOptionClick = (value, index) => {
    setopenDiscountBox(false);
    setPlaceholderValue(value);
    updateFieldValue(index, "discount_type", value);
    setMobile(true);
  };
  useEffect(() => {
    const InitalTotalAmouunt =
      ((Number(value.rate) * value.quantity) / 100) *
        (Number(value.sgst) + Number(value.cgst)) +
      value.rate * value.quantity;
    updateFieldValue(
      index,
      "amount",
      placeholderValue === "Percentage"
        ? InitalTotalAmouunt -
            (InitalTotalAmouunt * Number(value.discount || 0)) / 100
        : InitalTotalAmouunt -
            (InitalTotalAmouunt === 0 ? 0 : Number(value.discount || 0))
    );
    setsubTotal(
      placeholderValue === "Percentage"
        ? InitalTotalAmouunt -
            (InitalTotalAmouunt * Number(value.discount || 0)) / 100
        : InitalTotalAmouunt -
            (InitalTotalAmouunt === 0 ? 0 : Number(value.discount || 0))
    );
  }, [
    value.hsn_sac,
    value.rate,
    value.sgst,
    value.cgst,
    value.discount,
    placeholderValue,
  ]);
  return (
    <div className="mt-4">
      <div className="matr_name">{value.material_name}</div>
      <div className="p-3" style={{ background: "rgb(246 246 246)" }}>
        <div className="row">
          <div className="col-12">
            <div className="mater_header">HSN/SAC</div>
            <div className="d-flex align-items-center w-100">
              <div className="w-100">
                <input
                  type="number"
                  className="mob_input_fields"
                  placeholder="12345678"
                  onChange={(e) => {
                    updateFieldValue(index, "hsn_sac", e.target.value);
                    // console.log("arrayApihi", arrayApi);
                  }}
                  onKeyDown={(event) => {
                    const value = event.target.value;
                    if (
                      value.length >= 8 &&
                      event.keyCode !== 8 &&
                      event.keyCode !== 37 &&
                      event.keyCode !== 39
                    ) {
                      event.preventDefault();
                    }
                    return (
                      invalidchr.includes(event.key) && event.preventDefault()
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mater_header">UOM</div>
            <div className="uommobile">{value?.uom}</div>
          </div>
          <div className="col-6">
            <div className="mater_header">Quantity</div>
            <div className="d-flex align-items-center">
              <div>
                <input
                  type="number"
                  className="mob_input_fields"
                  value={value.quantity || ""}
                  onChange={(e) => {
                    setorderp(false);
                    updateFieldValue(index, "quantity", Number(e.target.value));
                    // console.log("arrayApihi", arrayApi);
                  }}
                />
              </div>
              {/* <div className="mob_tag">{value}</div> */}
            </div>
          </div>
          <div className="col-6">
            <div className="mater_header">Rate</div>
            <div className="d-flex align-items-center">
              <div>
                {" "}
                <div className="mob_tag">₹</div>
              </div>

              <input
                type="number"
                className="mob_input_fields"
                placeholder="Price"
                onChange={(e) => {
                  setorderp(false);
                  updateFieldValue(index, "rate", Number(e.target.value));
                  // console.log("arrayApihi", arrayApi);
                }}
                required
                onKeyDown={(event) => {
                  const value = event.target.value;
                  if (
                    value.length >= 10 &&
                    event.keyCode !== 8 &&
                    event.keyCode !== 37 &&
                    event.keyCode !== 39
                  ) {
                    event.preventDefault();
                  }
                  return (
                    invalidChars.includes(event.key) && event.preventDefault()
                  );
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mater_header">Discount</div>
            <div className="d-flex align-items-center justify-content-center">
              <span
                className={`${
                  placeholderValue === "Amount"
                    ? "input-euro left"
                    : placeholderValue === "Percentage" && Mobile
                    ? "perctange right"
                    : ""
                }`}
              >
                <input
                  disabled={!Mobile}
                  type="number"
                  className="mob_input_fields discountinput potable_input w-100 "
                  // value={value.discount || ""}
                  onChange={(e) => {
                    setorderp(false);
                    updateFieldValue(
                      index,
                      "discount",
                      Number(e.target.value) || 0
                    );
                    updateFieldValue(index, "discount_type", placeholderValue);
                  }}
                  placeholder="0"
                  onKeyDown={(event) => {
                    const currentValue = event.target.value;
                    const pattern = /^\d{0,3}(\.\d{0,2})?$/;
                    if (placeholderValue === "Percentage") {
                      if (
                        event.key === "Backspace" ||
                        event.keyCode === 37 ||
                        event.keyCode === 39
                      ) {
                        return;
                      }
                      const inputValue = event.target.value + event.key;
                      const numericValue = parseFloat(inputValue);
                      // if (
                      //   !/^\d*\.?\d*$/.test(inputValue) || // Allow only digits and a single decimal point
                      //   numericValue < 0 || // Prevent negative numbers
                      //   numericValue > 200 || // Prevent values greater than 100
                      //   (inputValue.includes(".") &&
                      //     inputValue.split(".")[1].length > 2) // Allow up to 2 decimal places
                      // ) {
                      //   event.preventDefault();
                      // }
                      const key = event.key;
                      const newValue = currentValue + key;
                      if (!pattern.test(newValue)) {
                        event.preventDefault();
                      }
                    } else {
                      const pattern = /^\d{0,6}(\.\d{0,5})?$/;
                      const currentValue = event.target.value;
                      if (
                        event.keyCode === 8 ||
                        event.keyCode === 37 ||
                        event.keyCode === 39
                      ) {
                        return;
                      }

                      const key = event.key;
                      const newValue = currentValue + key;
                      if (!pattern.test(newValue)) {
                        event.preventDefault();
                      }
                    }
                  }}
                />
              </span>
              <div
                className={`₹ discounttopdiv mobilebox ${
                  placeholderValue === "Percentage" ? "xyzper" : ""
                }`}
                onClick={() => setopenDiscountBox(!openDiscountBox)}
              >
                <KeyboardArrowDownIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      width: "18px",
                      height: "18px",
                      transform: openDiscountBox && "rotate(180deg)",
                    },
                  }}
                />
                {openDiscountBox && (
                  <div className="MaterialDiscount">
                    <p
                      onClick={() => {
                        setorderp(false);
                        handleOptionClick("Percentage", index);
                      }}
                      className="percetnagepara"
                    >
                      Percentage
                    </p>
                    <p
                      onClick={() => {
                        setorderp(false);
                        handleOptionClick("Amount", index);
                      }}
                      className="amountpara"
                    >
                      Amount
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mater_header">CGST</div>
            <div className="d-flex align-items-center">
              <div>
                <input
                  className={`mob_input_fields w-100 ${
                    value.sgst !== value.cgst && "errorfile"
                  }`}
                  type="number"
                  required=""
                  // value={value.cgst || ""}
                  onChange={(e) => {
                    setorderp(false);
                    updateFieldValue(index, "cgst", Number(e.target.value));
                    // console.log("arrayApihi", arrayApi);
                  }}
                  placeholder="eg. 10"
                  onKeyDown={(event) => {
                    const pattern = /^\d{0,5}(\.\d{0,4})?$/;
                    const currentValue = event.target.value;

                    if (
                      event.keyCode === 8 ||
                      event.keyCode === 37 ||
                      event.keyCode === 39
                    ) {
                      return;
                    }

                    const key = event.key;
                    const newValue = currentValue + key;
                    if (!pattern.test(newValue)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="mob_tag">%</div>
            </div>
          </div>
          <div className="col-6">
            <div className="mater_header">SGST</div>
            <div className="d-flex align-items-center">
              <input
                type="number"
                className="mob_input_fields"
                required=""
                // value={value.sgst || ""}
                onChange={(e) => {
                  setorderp(false);
                  updateFieldValue(index, "sgst", Number(e.target.value));
                  // console.log("arrayApihi", arrayApi);
                }}
                placeholder="eg 10"
                onKeyDown={(event) => {
                  const pattern = /^\d{0,5}(\.\d{0,4})?$/;
                  const currentValue = event.target.value;

                  if (
                    event.keyCode === 8 ||
                    event.keyCode === 37 ||
                    event.keyCode === 39
                  ) {
                    return;
                  }

                  const key = event.key;
                  const newValue = currentValue + key;
                  if (!pattern.test(newValue)) {
                    event.preventDefault();
                  }
                }}
              />
              <div>
                {" "}
                <div className="mob_tag">%</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="total_amt_mob">
            <span>Total Amount</span>
          </div>
          <div className="total_amt_mob">
            <span>
              {" "}
              ₹ {Number.isNaN(subTotal) ? 0 : Number(subTotal).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
