import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import logo from "../Assets/svg/cendrol_logo.svg";
import ExistingRow from "./ExistingRow";
import { ToWords } from "to-words";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import success from "../Assets/svg/succes.svg";
import contact_us from "../Assets/svg/Calling.svg";
import contact_us2 from "../Assets/svg/Calling2.svg";
import MobileTable from "./MobileTable";
import Footer from "./Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const invalidChars = ["-", "+", "e"];
export default function Table({
  dataM,
  updateFieldValue,
  matrialID,
  open1,
  handleClose1,
}) {
  const [Transportation, setTransportation] = useState(0);
  const [Unloading, setUnloading] = useState(0);
  const [TotalInvoice, setTotalInvoice] = useState(0);
  const [delivery_time, setdelivery_time] = useState("");
  const [discount, setdiscount] = useState(0);
  const [Gtotl, setGtotl] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderp, setorderp] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toWords = new ToWords();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const today = new Date().toISOString().split("T")[0];
  // Delivery Date picker only used in mobile view
  const currentDate = new Date();
  const datePickerRef = useRef(null);
  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setdelivery_time(formattedDate);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };
  let l;
  // calculation
  const calculateSubTotle = () => {
    let subtotal = 0;
    for (let i = 0; i < dataM?.length; i++) {
      subtotal += dataM[i].amount;
    }

    return subtotal;
  };
  let TotalDiscountValue1 = 0;
  let TotalDiscountValue2 = 0;
  const CalculateTotalDiscount = () => {
    TotalDiscountValue1 = 0;
    TotalDiscountValue2 = 0;
    dataM?.map((lp, index) => {
      if (lp?.discount_type === "Percentage") {
        TotalDiscountValue1 +=
          (lp.amount * 100) / (100 - lp.discount) - lp?.amount
            ? (lp.amount * 100) / (100 - lp.discount) - lp?.amount
            : 0;
      } else if (lp.discount_type === "Amount") {
        TotalDiscountValue2 += lp.discount ? lp.discount : 0;
      }
    });
    return (
      (TotalDiscountValue1 ? TotalDiscountValue1 : 0) +
      (TotalDiscountValue2 ? TotalDiscountValue2 : 0)
    );
  };

  function validateArrayOfObjects(arr) {
    let isValid = true;

    for (let i = 0; i < arr?.length; i++) {
      const obj = arr[i];
      console.log("Vaidation", obj);
      console.log("Vaidation", arr);
      if (!obj.rate || !(obj.sgst + 1) || !(obj.cgst + 1)) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }
  const MessgaeHsn = (arr) => {
    let isValid = true;
    for (let i = 0; i < arr?.length; i++) {
      const obj = arr[i];
      if (!obj?.hsn_sac) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const SgstcgstValidate = (arr) => {
    let isValid = true;
    for (let i = 0; i < arr?.length; i++) {
      const obj = arr[i];
      if (obj?.cgst !== obj?.sgst) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  // totle
  const TotalInvoiceValue = () => {
    let SubTotle_data = calculateSubTotle();

    let InvoiceTotle =
      Number(SubTotle_data) + Number(Transportation) + Number(Unloading);
    setTotalInvoice(InvoiceTotle);
  };

  const SubmitData = () => {
    if (isSubmitting) {
      return; // If submission is already in progress, prevent further calls
    }
    let data = {
      material_category: matrialID,
      materials: dataM,
      expected_delivery_date: delivery_time,
      sub_total: calculateSubTotle(),
      transportation_charges: Transportation,
      load_unload_charges: Unloading,
      total: TotalInvoice,
      discount: CalculateTotalDiscount(),
    };

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    if (!validateArrayOfObjects(dataM)) {
      toast.info(`Please Fill The Details`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (delivery_time === "") {
      toast.info(`Please Fill the expected Date of Delivery`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (Gtotl == false) {
      toast.info(`Please Click on Grand Total`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (delivery_time) {
    //   toast.info(`delivery_time should be number`, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else {
      setIsSubmitting(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/create-rfq`, data, config)
        .then((response) => {
          console.log("response", response.data.status);
          if (response.data.status === "succes") {
            // toast.success(`${response.data.msg}`, {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            handleOpen();
            setIsSubmitting(false);
          } else {
            toast.error(`${response.data.msg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
          window.location.href = "https://404.cendrol.com/";
        });
    }
  };
  useEffect(() => {
    // handleOpen();
  }, []);
  return (
    <div style={{ overflowX: "hidden" }}>
      <Modal
        open={open1}
        onClose={handleClose1}
        // style={{ background: " #F5F5F5" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <div className="succesmsg">
            <p className="Successfully">
              "Welcome back! It looks like you've already submitted a quote for
              this project. If you need to make any changes or updates, please
              contact us directly."
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ background: " #F5F5F5" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <img src={logo} className="mb-5" />
          <div className="succesmsg">
            <img src={success} alt="..." />
            <p className="Successfully">
              {/* Thank you for submitting your quote! Our team will review your
              information and get back to you as soon as possible. */}
              Submit Successfully
            </p>
          </div>
        </div>
      </Modal>
      <div className="main_div w-100">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="d-flex justify-content-between align-items-center">
              <div className="p-3 mt-3 mb-3">
                <img src={logo} width="100" />
              </div>
              <div className="p-3 mt-3 mb-3">
                <a href="tel:9845925477" className="contact_us_no">
                  <img src={contact_us2} width="30" />
                  <span className="contact_us_text">Contact Us</span>
                </a>
              </div>
            </div>
            <div className="p-3" style={{ background: "#fff" }}>
              <span className="main_head">Request For Quotation</span>
              <div className="outer_div mt-3">
                <span className="table_head">Material Details</span>
                <table className="w-100 mt-3">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#F7B314",
                        color: "black",
                        fontSize: "13px",
                        fontFamily: "Gsb",
                      }}
                    >
                      <th className="text-center header_sec">Sl.No</th>
                      <th className="header_sec" style={{ width: "25%" }}>
                        Material Name
                      </th>
                      <th className="text-center header_sec">HSN/SAC</th>
                      <th className="text-center header_sec">UOM</th>
                      <th className="text-center header_sec">Quantity</th>
                      <th className="text-center header_sec">Rate</th>
                      <th className="text-center header_sec">SGST</th>
                      <th className="text-center header_sec">CGST</th>
                      <th className="text-center header_sec">Discount</th>
                      <th
                        className="text-center header_sec"
                        onClick={() => {
                          console.log("dataM", dataM);
                        }}
                      >
                        Total Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="t_body">
                    {dataM?.map((value, index, arr) => {
                      return (
                        <>
                          <ExistingRow
                            value={value}
                            updateFieldValue={updateFieldValue}
                            index={index}
                            setorderp={setorderp}
                          />
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="w-100">
                  <div className="calc d-flex Cal_text">
                    <div className="w-50"></div>
                    <div className="w-50 Cal_details">
                      <div className=" mb-3 mt-3">
                        <div className="row mt-2">
                          <div className="col-8 text-end">
                            <span>Sub Total </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">
                              ₹{" "}
                              {Number.isNaN(calculateSubTotle())
                                ? 0
                                : calculateSubTotle().toFixed(2)}
                            </div>
                          </div>
                        </div>
                        {/* <div className="row mt-2">
                          <div className="col-8 text-end">
                            <span>CGST </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">1,64,430.00</div>
                          </div>
                        </div> */}
                        {/* <div className="row mt-2">
                          <div className="col-8 text-end">
                            <span>SGST </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">1,64,430.00</div>
                          </div>
                        </div> */}

                        <div className="row mt-2">
                          <div className="col-8 text-end">
                            <span>Discount </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">
                              <div className="text-center">
                                {/* {"₹" + CalculateTotalDiscount()?.toFixed(2) ||
                                  "-"} */}
                                ₹{" "}
                                {
                                  ((l = dataM.every(
                                    (item) =>
                                      item.amount === 0 || isNaN(item?.amount)
                                  )),
                                  l
                                    ? 0
                                    : (CalculateTotalDiscount() || 0).toFixed(
                                        2
                                      ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-8 text-end">
                            <span>Transportation Charges </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <p className="₹ mb-0">₹</p>
                                <input
                                  // value={Transportation}
                                  onChange={(e) => {
                                    setorderp(false);
                                    setTransportation(Number(e.target.value));
                                  }}
                                  className="potable_input w-50"
                                  type="number"
                                  required=""
                                  placeholder="eg .72.00"
                                  onKeyDown={(event) => {
                                    const value = event.target.value;
                                    if (
                                      value.length >= 11 &&
                                      event.keyCode !== 8 &&
                                      event.keyCode !== 37 &&
                                      event.keyCode !== 39
                                    ) {
                                      event.preventDefault();
                                    }
                                    return (
                                      invalidChars.includes(event.key) &&
                                      event.preventDefault()
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-8 text-end">
                            <span>Loading & Unloading Charges </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">
                              <div className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <p className="₹ mb-0">₹</p>
                                  <input
                                    // value={Unloading}
                                    onChange={(e) => {
                                      setorderp(false);
                                      setUnloading(Number(e.target.value));
                                    }}
                                    className="potable_input w-50"
                                    type="number"
                                    required=""
                                    placeholder="eg .72.00"
                                    onKeyDown={(event) => {
                                      const value = event.target.value;
                                      if (
                                        value.length >= 11 &&
                                        event.keyCode !== 8 &&
                                        event.keyCode !== 37 &&
                                        event.keyCode !== 39
                                      ) {
                                        event.preventDefault();
                                      }
                                      return (
                                        invalidChars.includes(event.key) &&
                                        event.preventDefault()
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" d-flex justify-content-end align-items-center">
                          <button
                            disabled={!validateArrayOfObjects(dataM)}
                            className={`calButton mt-2 ${
                              !validateArrayOfObjects(dataM) && "groundtotal"
                            }`}
                            onClick={() => {
                              setorderp(true);
                              if (!MessgaeHsn(dataM)) {
                                toast.info("Please Fill The HSN/SAC", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              } else if (!SgstcgstValidate(dataM)) {
                                toast.info(
                                  "CGST and SGST should be the same.",
                                  {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                              } else {
                                if (!validateArrayOfObjects(dataM)) {
                                } else {
                                  setGtotl(true);
                                  TotalInvoiceValue();
                                }
                              }
                            }}
                          >
                            <p className="mb-0">Calculate Grand Total</p>
                          </button>
                        </div>

                        <div className="row mt-2 total_mt">
                          <div className="col-8 text-end">
                            <span>Total </span>
                          </div>
                          <div className="col-4">
                            <div className="text-center">
                              ₹ {Math.round(TotalInvoice?.toFixed(2))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="in_words">
                  Total In Words :{" "}
                  {/* {toWords.convert(Number(TotalInvoice?.toFixed(2) || 0))} Rupee */}
                  {toWords.convert(
                    Number(Math.round(TotalInvoice?.toFixed(2) || 0)),
                    {
                      currency: true,
                      ignoreDecimal: true,
                    }
                  )}{" "}
                </div>
              </div>
              <div className="del_details">
                <span>Expected Delivery Date</span>
                <div className="d-flex align-content-center">
                  <input
                    type="date"
                    value={delivery_time}
                    onChange={(e) => {
                      setdelivery_time(e.target.value);
                    }}
                    className="del_input"
                    placeholder="eg.15"
                    min={today}
                  />
                  <div
                    style={{
                      background: " #FFEDC2",
                      padding: "8px 11px 1px 13px",
                    }}
                  >
                    Date
                  </div>
                </div>
                <hr />
                <button
                  disabled={
                    !orderp || !MessgaeHsn(dataM) || !SgstcgstValidate(dataM)
                  }
                  className={`submit_det ${
                    (!orderp && "groundtotal") ||
                    (!MessgaeHsn(dataM) && "groundtotal") ||
                    (!SgstcgstValidate(dataM) && "groundtotal")
                  }`}
                  onClick={() => {
                    SubmitData();
                  }}
                >
                  Submit Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {windowWidth < 500 && (
          <div className="mobile_section">
            <div className="d-flex justify-content-between align-items-center">
              <div className="p-3 mt-3 mb-3">
                <img src={logo} width="100" />
              </div>
              <div className="p-3 mt-3 mb-3">
                <img src={contact_us} width="30" />
                <span className="contact_us_text">Contact Us</span>
              </div>
            </div>
            <div className="p-3 rfq_mobile_main">
              <div>
                <span className="mob_main_heading">Request For Quotation</span>
                <div className="mt-3">
                  <span className="mob_subheading">Material Details</span>
                </div>
                {dataM?.map((value, index) => {
                  return (
                    <MobileTable
                      value={value}
                      updateFieldValue={updateFieldValue}
                      index={index}
                      setorderp={setorderp}
                    />
                  );
                })}
                <div className="mob_sub_total_div">
                  <div className="d-flex justify-content-between sub_total">
                    <div className="MvsubtotaltitleContainer">
                      <p className="subtotaltitle">Sub Total</p>
                    </div>
                    <div className="">
                      <span>
                        ₹{" "}
                        {Number.isNaN(calculateSubTotle())
                          ? 0
                          : calculateSubTotle().toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between sub_total">
                    <div className="MvstotaldiscountContainer">
                      <p className="totaldiscountheading">Total Discount</p>
                    </div>
                    <div>
                      <span>
                        ₹{" "}
                        {
                          ((l = dataM.every(
                            (item) => item.amount === 0 || isNaN(item?.amount)
                          )),
                          l ? 0 : (CalculateTotalDiscount() || 0).toFixed(2))
                        }
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mt-3 other_fields">
                    <div className="col-6">
                      <p className="Transportationtitle">
                        Transportation Charges
                      </p>
                    </div>
                    <div className="col-6">
                      <div className="d-flex">
                        <div className="mob_tag">₹</div>
                        <div>
                          <input
                            className="mob_input_fields"
                            // value={Transportation}
                            onChange={(e) => {
                              setorderp(false);
                              setTransportation(Number(e.target.value || 0));
                            }}
                            type="number"
                            required=""
                            placeholder="eg .72.00"
                            onKeyDown={(event) => {
                              const value = event.target.value;
                              if (
                                value.length >= 11 &&
                                event.keyCode !== 8 &&
                                event.keyCode !== 37 &&
                                event.keyCode !== 39
                              ) {
                                event.preventDefault();
                              }
                              return (
                                invalidChars.includes(event.key) &&
                                event.preventDefault()
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-3 other_fields">
                    <div className="col-6">
                      <p className="Loadingtitle">
                        Loading & Unloading Charges
                      </p>
                    </div>
                    <div className="col-6">
                      <div className="d-flex">
                        <div className="mob_tag">₹</div>
                        <div>
                          <input
                            className="mob_input_fields"
                            // value={Unloading}
                            onChange={(e) => {
                              setorderp(false);
                              setUnloading(Number(e.target.value));
                            }}
                            type="number"
                            required=""
                            placeholder="eg .72.00"
                            onKeyDown={(event) => {
                              const value = event.target.value;
                              if (
                                value.length >= 11 &&
                                event.keyCode !== 8 &&
                                event.keyCode !== 37 &&
                                event.keyCode !== 39
                              ) {
                                event.preventDefault();
                              }
                              return (
                                invalidChars.includes(event.key) &&
                                event.preventDefault()
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      // disabled={!validateArrayOfObjects(dataM)}
                      className="mob_cal_btn1 w-100 mt-2"
                      style={{ cursor: "pointer" }}
                      // onClick={() => {
                      //   setorderp(true);
                      //   if (!validateArrayOfObjects(dataM)) {
                      //   } else {
                      //     setGtotl(true);
                      //     TotalInvoiceValue();
                      //   }
                      // }}
                      onClick={() => {
                        setorderp(true);
                        if (!MessgaeHsn(dataM)) {
                          toast.info("Please Fill The HSN/SAC", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        } else if (!SgstcgstValidate(dataM)) {
                          toast.info("CGST and SGST should be the same.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        } else {
                          if (!validateArrayOfObjects(dataM)) {
                          } else {
                            setGtotl(true);
                            TotalInvoiceValue();
                          }
                        }
                      }}
                    >
                      <p className="mb-0">Calculate Grand Total</p>
                    </button>

                    <hr />
                    <div className="d-flex justify-content-between sub_total">
                      <div className="total_amt_mob">
                        <span>Grand Total</span>
                      </div>
                      <div className="total_amt_mob">
                        <span> ₹ {TotalInvoice?.toFixed(2)}</span>
                      </div>
                    </div>
                    {/* <button className="mob_cal_btn">Calculate Grand Total</button> */}
                  </div>
                </div>
                <div className="other_fields mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="DeliveryDatepara">Expected Delivery Date</p>
                    </div>
                    <div>
                      {/* <input
                            type="date"
                            className="mob_input_fields"
                            value={delivery_time}
                            onChange={(e) => {
                              setdelivery_time(e.target.value);
                            }}
                            placeholder="eg.15"
                          /> */}
                      <DatePicker
                        ref={datePickerRef}
                        selected={
                          delivery_time ? new Date(delivery_time) : null
                        }
                        minDate={currentDate}
                        className="mob_input_fields xyzww"
                        placeholderText="DD - MM - YYYY"
                        onChange={(date) => handleDateChange(date)}
                        showIcon
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-50 mt-3 ms-auto">
                  <button
                    disabled={
                      !orderp || !MessgaeHsn(dataM) || !SgstcgstValidate(dataM)
                    }
                    className="mob_cal_btn"
                    onClick={() => {
                      SubmitData();
                    }}
                  >
                    Submit Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
