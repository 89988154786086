import axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";
import Table from "./Components/Table";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./Components/ErrorPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import Modal from "@mui/material/Modal";
import $ from "jquery";
function App() {
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const [matrialID, setMatrialID] = useState("");
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [dataM, setDataM] = useState([]);
  const updateFieldValue = (index, fieldName, value) => {
    setDataM((prevData) => {
      let newData = [...prevData];
      newData[index][fieldName] = value;
      return newData;
    });
  };

  const getvendorData = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/check-token`,
        // headers: {
        //   Authorization: "Bearer " + token,
        // },
        { token: token }
      )
      .then((response) => {
        // console.log("response", response.data.result.material_category);
        setMatrialID(response.data.result?.material_category);
        setDataM(
          response.data.result?.materials?.map((v, ind) => ({
            ...v,
            writable: true,
            rate: 0,
            gst: 0,
            discount: 0,
            amount: 0,
          }))
        );

        if (response.data.msg === "Already added RFQ!") {
          handleOpen1();
        }
        localStorage.setItem("token", response.data.token);
      })
      .catch((err) => {
        console.log(err);
        handleOpen1();
        // window.location.href = "https://404.cendrol.com/";
      });
  };
  useEffect(() => {
    if (token === null) {
      window.location.href = "https://404.cendrol.com/";
    } else {
      // window.location.href = "https://404.cendrol.com/";

      getvendorData();
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Table
              open1={open1}
              handleClose1={handleClose1}
              dataM={dataM}
              updateFieldValue={updateFieldValue}
              matrialID={matrialID}
            />
          }
        />
        {/* <Route exact path="/404" element={<ErrorPage />} /> */}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
